import * as React from "react"
import styled from 'styled-components'
import Button from '../components/button'
import { color, font } from '../styles'
import { Helmet } from 'react-helmet'

const Wrapper = styled.main`
  max-width: 42.5rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  @media (min-width: 1280px) {
    padding: 8rem 4rem;
  }
  @media (max-width: 1279px) {
    padding: 1rem;
  }
`
const Header = styled.h1`
  text-align: center;
  margin-bottom: 1rem;
`
const Subheader = styled.p`
  ${font.p}
  text-align: center;
  color: ${color.primary};
  margin-bottom: 2rem;
`

const NotFoundPage = () => {
  return (
    <Wrapper>
      <Helmet>
        <title>{`404: Page Not Found | Design Foundations`}</title>
        <meta name="description" content={`A free introduction to product design. Learn the core concepts in visual design, interaction design, product management, and user research.`} />
      </Helmet>
      <Header>Sorry, that page doesn't exist!</Header>
      <Subheader>Click below to view an introduction to Design Foundations, or use the navigation bar to find an article.</Subheader>
      <Button to="/introduction/introduction/">Go to Introduction</Button>
    </Wrapper>
  )
}

export default NotFoundPage
